import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';

import css from './ListingPage.module.css';
import FavoriteButton from '../../components/FavoriteButton/FavoriteButton';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    authorLink,
    showContactUser,
    onContactUser,
    currentUser,
    listing,
    onUpdateProfile
  } = props;

  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
      ? 'ListingPage.perDay'
      : 'ListingPage.perUnit';

  const handleClickFavorite = async (clicked) => {
    let favorites = currentUser.attributes.profile.protectedData.listingFavorites.map(fav => JSON.parse(fav));
    const favoriteIds = favorites.map(fav => fav.id.uuid);
    const id = listing.id.uuid;
    if (!favorites) {
      favorites = [];
    }

    if (favoriteIds.includes(id) && !clicked) {
      favorites.splice(favoriteIds.indexOf(id), 1);
    }
    else if (!favoriteIds.includes(id) && clicked) {
      favorites.push(listing);
    }
    await onUpdateProfile({ protectedData: { listingFavorites: favorites.map(fav => JSON.stringify(fav)) } });
    await onUpdateFavorites(currentUser.attributes.email + "|" + currentUser.attributes.profile.displayName, listing.id, clicked);
  }

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        <div className={css.desktopPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>
      <div className={css.heading}>
        <div className={css.orderAndHeart}>
          <h1 className={css.title}>{richTitle}</h1>
          {!!currentUser && currentUser?.id?.uuid !== listing?.author?.id?.uuid && <FavoriteButton clicked={currentUser?.attributes?.profile?.protectedData?.listingFavorites?.map(fav => JSON.parse(fav)?.id?.uuid).includes(listing?.id?.uuid)} onClick={(clicked) => handleClickFavorite(clicked)}></FavoriteButton>}
        </div>
        <div className={css.author}>
          {category}
          <FormattedMessage id="ListingPage.soldBy" values={{ name: authorLink }} />
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
